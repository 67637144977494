import type { GetServerSideProps, NextPage } from 'next';
import { signIn } from 'next-auth/react';
import { parseBody } from 'next/dist/server/api-utils/node';
import { useEffect } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

type PublicPage<P = { token: string }, IP = P> = NextPage<P, IP> & {
  isPublicPage: boolean;
};

interface LoginProps {
  token: string | undefined;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Login: PublicPage = ({ token }: LoginProps) => {
  useEffect(() => {
    function handleLogin() {
      if (token) {
        signIn('motus-identity-server-islykill', {
          callbackUrl: sessionStorage.getItem('redirectUrl') ?? '/opinmal',
          token: token,
        });
      } else {
        signIn('motus-identity-server', { callbackUrl: sessionStorage.getItem('redirectUrl') ?? '/opinmal' });
      }
    }
    handleLogin();
  }, [token]);

  return (
    <Box sx={{ background: '#f0f0f0 !important', height: '100%', width: '100%' }}>
      <Box sx={{ position: 'absolute', m: '0px', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <CircularProgress />
        <Typography sx={{ pt: '45px', transform: 'translate(-35%, -50%)' }} variant="body1">
          Vinn úr auðkenningu...
        </Typography>
      </Box>
    </Box>
  );
};

export const getServerSideProps: GetServerSideProps<LoginProps> = async (context) => {
  let token = null;
  if (context.req.method === 'POST') {
    const body = await parseBody(context.req, '1mb');
    token = body.token;
  }

  return { props: { token } };
};

Login.isPublicPage = true;

export default Login;
